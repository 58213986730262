import { addDays, isBefore, startOfDay } from 'date-fns';

import { apiService, type InvitedUser } from '../../../services/api-service';
import { type OrganizerSelectOption } from '../../Organization/OrganizerSelectGroup';

export class EventScheduleUtils {
  static async AttendeesToUids(
    orgId: string,
    attendees: OrganizerSelectOption[]
  ) {
    const uidSet = new Set<string>();

    const invitedUsers: InvitedUser[] = [];
    for (const attendee of attendees) {
      if (attendee.kind === 'slack-user') {
        invitedUsers.push({
          email: attendee.user.email,
          fullName: attendee.user.fullName,
          exUserId: attendee.user.id,
        });
      }
      if (attendee.kind === undefined) {
        invitedUsers.push({
          email: attendee.value,
        });
      }
      if (attendee.kind === 'organizer') {
        uidSet.add(attendee.organizer.uid);
      }
    }
    const resp = await apiService.organization.inviteOrganizers(orgId, {
      invitedUsers,
      webEndpoint: window.location.origin,
      sendEmail: true,
    });
    for (const organizer of resp.data.organizers) {
      uidSet.add(organizer.uid);
    }

    return Array.from(uidSet);
  }

  static IsDateTooSoon(date: Date) {
    return isBefore(date, startOfDay(addDays(new Date(), 3)));
  }

  static IsAmericaHoliday(date: Date) {
    const month = date.getMonth();
    const day = date.getDate();
    const dayOfWeek = date.getDay();

    // New Year's Day
    if (month === 0 && day === 1) {
      return true;
    }
    // Martin Luther King, Jr. Day (third Monday in January)
    if (month === 0 && dayOfWeek === 1 && day >= 15 && day <= 21) {
      return true;
    }
    // President's Day (third Monday in February)
    if (month === 1 && dayOfWeek === 1 && day >= 15 && day <= 21) {
      return true;
    }
    // Memorial Day (last Monday in May)
    if (month === 4 && dayOfWeek === 1 && day >= 25 && day <= 31) {
      return true;
    }
    // Juneteenth National Independence Day
    if (month === 5 && day === 19) {
      return true;
    }
    // Independence Day
    if (month === 6 && day === 4) {
      return true;
    }
    // Labor Day (first Monday in September)
    if (month === 8 && dayOfWeek === 1 && day >= 1 && day <= 7) {
      return true;
    }
    // Columbus Day (second Monday in October)
    if (month === 9 && dayOfWeek === 1 && day >= 8 && day <= 14) {
      return true;
    }
    // Veterans Day
    if (month === 10 && day === 11) {
      return true;
    }
    // Thanksgiving Day (fourth Thursday in November)
    if (month === 10 && dayOfWeek === 4 && day >= 22 && day <= 28) {
      return true;
    }
    // Christmas Day
    if (month === 11 && day === 25) {
      return true;
    }
    return false;
  }
}
