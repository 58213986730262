import { useNavigate } from '@remix-run/react';
import { addMinutes } from 'date-fns';
import { useMemo } from 'react';
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Select from 'react-select';
import { useEffectOnce } from 'react-use';

import {
  type DtoEvent,
  type DtoOrganizer,
  EnumsEventType,
  EnumsOnboardingTaskName,
  EnumsOnboardingTaskOperation,
  EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { useEventSchedulerAnalytics } from '../../../analytics/eventScheduler';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { fromDTOGamePack } from '../../../utils/api-dto';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { ChatBubbleIcon } from '../../icons/Chat/ChatBubbleIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { TimerIcon } from '../../icons/TimerIcon';
import { PurchaseMethod } from '../../OneTimePurchase/type';
import {
  useMyOrganization,
  useMyOrganizationFeatureChecker,
} from '../../Organization';
import { useUser } from '../../UserContext';
import {
  EventScheduleAttendeesField,
  EventScheduleEventTimeField,
  EventScheduleGamePackField,
} from './EventScheduleShared';
import { type EventScheduleOnDFormData } from './types';
import { EventScheduleUtils } from './utils';

function durationToOption(duration: number): Option<number> {
  return {
    value: duration,
    label: `${duration} minutes`,
  };
}

const DurationOptions = [15, 30, 45, 60].map(durationToOption);

function DurationField() {
  const {
    field: { value, onChange },
  } = useController<EventScheduleOnDFormData, 'durationMin'>({
    name: 'durationMin',
  });

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<number>>({
        override: {
          control: { height: '40px' },
        },
      }),
    []
  );

  return (
    <div className='w-full flex gap-4'>
      <div className='mt-1'>
        <TimerIcon className='fill-current w-6 h-6' />
      </div>
      <div className='w-full'>
        <div className='text-sms font-bold'>Meeting Duration</div>
        <div className='text-sms text-icon-gray'>
          How much time should we block out on invitees calendar?
        </div>

        <div className='mt-5 w-75'>
          <Select<Option<number>, false>
            options={DurationOptions}
            value={value ? durationToOption(value) : null}
            styles={styles}
            classNamePrefix='select-box-v2'
            onChange={(option) => option && onChange(option.value)}
          />
        </div>
      </div>
    </div>
  );
}

function InviteMessageField() {
  const { register } = useFormContext<EventScheduleOnDFormData>();

  return (
    <div className='w-full flex gap-4'>
      <div className='mt-1'>
        <ChatBubbleIcon className='fill-current w-6 h-6' />
      </div>
      <div className='w-full'>
        <div className='text-sms font-bold'>Invite message</div>
        <div className='text-sms text-icon-gray'>
          Add message to attendees (optional)
        </div>

        <div className='mt-5 w-full'>
          <textarea
            {...register('message', {
              maxLength: 1000,
            })}
            className='w-full h-20 field mb-0 px-5 py-4'
            placeholder='Let’s hang out on Luna Park!'
            maxLength={1000}
          />
        </div>
      </div>
    </div>
  );
}

function DeleteConfirmationModal(): JSX.Element {
  const analytics = useEventSchedulerAnalytics();
  useEffectOnce(() => {
    analytics.trackDeleteConfirmationViewed();
  });

  return (
    <div className='text-white flex-col items-center justify-center py-2 px-4'>
      <ConfirmCancelModalText className='text-2xl font-medium'>
        Delete this event?
      </ConfirmCancelModalText>
      <div className='my-2 text-sms text-center'>
        When you delete an event we'll notify the attendees. This action cannot
        be undone.
      </div>
    </div>
  );
}

function DeleteEventButton(props: { event: DtoEvent }) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const analytics = useEventSchedulerAnalytics();
  const navigate = useNavigate();

  const {
    call: handleClick,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: <DeleteConfirmationModal />,
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
    });
    if (response.result !== 'confirmed') {
      analytics.trackDeleteConfirmationDismissed();
      return;
    }
    analytics.trackDeleteConfirmationConfirmed();

    await apiService.event.cancelEvent(props.event.id);
    navigate(-1);
  });

  return (
    <div className='ml-10'>
      <button
        type='button'
        className='flex items-center gap-2 text-red-004 text-sms'
        onClick={handleClick}
        disabled={isRunning}
      >
        <DeleteIcon className='fill-current w-3 h-3' />
        <p>Delete Event</p>
      </button>
    </div>
  );
}

export interface EventScheduleOnDProps {
  event?: DtoEvent;
  initData: Partial<EventScheduleOnDFormData>;
  recommendedAttendees?: DtoOrganizer[][];
  backTo?: string | null;
}

export function EventScheduleOnD(props: EventScheduleOnDProps) {
  const { event, initData, backTo } = props;

  const navigate = useNavigate();
  const analytics = useEventSchedulerAnalytics();
  const organization = useMyOrganization();
  const user = useUser();
  const featureChecker = useMyOrganizationFeatureChecker();

  const form = useForm<EventScheduleOnDFormData>({
    mode: 'onChange',
    defaultValues: initData,
  });
  const { watch, formState } = form;
  const pack = watch('pack');

  const eventType = EnumsEventType.EventTypeOnd;
  const hasPermission =
    !pack || featureChecker.canAccessGamePack(fromDTOGamePack(pack));

  const handleSubmit = form.handleSubmit(async (data) => {
    if (!organization || !data.pack || !data.eventTime) return;

    const endAt = addMinutes(data.eventTime.startDate, data.durationMin);

    analytics.trackFormSubmitted({
      orgId: organization.id,
      type: eventType,
      eventType,
      gamePackId: data.pack.id,
      gamePackName: data.pack.name,
      startAt: data.eventTime.startDate.toISOString(),
      endAt,
      timezone: data.eventTime.timezone,
      durationMin: data.durationMin,
      attendeeUids: data.attendees,
      totalAttendees: data.attendees.length + 1,
      hasMessage: !!data.message,
      purchaseMethod: organization?.purchase?.gamePackIds.includes(data.pack.id)
        ? PurchaseMethod.OneTimePurchase
        : undefined,
    });

    const attendeeUids = await EventScheduleUtils.AttendeesToUids(
      organization.id,
      data.attendees
    );

    if (event) {
      apiService.onboarding.operateTask(
        EnumsOnboardingTaskName.OnboardingTaskNameScheduleOndGame,
        {
          orgId: organization.id,
          operation:
            EnumsOnboardingTaskOperation.OnboardingTaskOperationMarkDone,
        }
      );
      const resp = await apiService.event.updateEvent(event.id, {
        gamePackId: data.pack.id,
        startAt: data.eventTime.startDate.toISOString(),
        endAt: endAt.toISOString(),
        timezone: data.eventTime.timezone,
        attendees: attendeeUids,
        message: data.message,
      });
      navigate(`/events/${resp.data.event.id}`);
    } else {
      const resp = await apiService.event.create({
        type: eventType,
        orgId: organization.id,
        gamePackId: data.pack.id,
        message: data.message,
        startAt: data.eventTime.startDate.toISOString(),
        endAt: endAt.toISOString(),
        timezone: data.eventTime.timezone,
        organizerUid: user.id,
        attendees: attendeeUids,
      });
      navigate(`/events/${resp.data.event.id}`);
    }
  });

  const handleCancel = () => {
    analytics.trackFormDismissed({
      eventType,
    });

    if (backTo) {
      navigate(backTo);
      return;
    }
    navigate(-1);
  };

  return (
    <FormProvider {...form}>
      <form
        className='
          w-full min-h-full bg-game-library bg-w-full bg-no-repeat bg-top bg-local
          flex text-white 
        '
        onSubmit={handleSubmit}
      >
        <EventScheduleGamePackField
          eventType={eventType}
          pageName={EnumsPageName.PageNamePublicHome}
          hasPermission={hasPermission}
        />

        <div className='flex-1 max-w-192 p-12.5 flex flex-col gap-10'>
          <div>
            <div className='flex items-center gap-2 text-3.5xl font-bold'>
              Let's Schedule Your Experience
            </div>
            {/* <div className='mt-2 text-icon-gray text-base font-normal'>
              Completing this form will send you a calendar invite for your
              event with instructions on how to get started. We’ll also email a
              calendar invite to any guests you add below.
            </div> */}
          </div>
          <div className='flex flex-col gap-7.5'>
            <EventScheduleEventTimeField eventType={eventType} />
            <DurationField />
            <EventScheduleAttendeesField
              eventType={eventType}
              recommendedAttendees={props.recommendedAttendees}
            />
            <InviteMessageField />
            {event && <DeleteEventButton event={event} />}
          </div>

          <div className='flex justify-end gap-5'>
            <button
              type='button'
              className='btn btn-secondary w-40 h-10'
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type='submit'
              className='btn-primary w-40 h-10 flex items-center justify-center gap-2'
              disabled={
                !hasPermission || formState.isSubmitting || !formState.isValid
              }
            >
              {form.formState.isSubmitting ? 'Submitting' : 'Submit'}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
