import { eachMinuteOfInterval, endOfDay, format, startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import isString from 'lodash/isString';
import throttle from 'lodash/throttle';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Waypoint } from 'react-waypoint';
import { match } from 'ts-pattern';

import {
  type DtoGamePack,
  EnumsEventType,
} from '@lp-lib/api-service-client/public';

import { isGamePackLaunched } from '../../../app/components/GamePack/utils';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { type EventType } from '../../services/api-service/event.api';
import { type EventTime } from '../../utils/date';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { CalendarIcon } from '../icons/CalendarIcon';
import { XIcon } from '../icons/XIcon';
import { TimezoneSelect } from '../TimezoneSelect';
import { EventDatePicker } from './EventDatePicker';
import { EventScheduleUtils } from './Schedule/utils';

const EVENT_TIME_SECTIONS = ['Morning', 'Afternoon', 'Evening'] as const;

type EventTimeSection = (typeof EVENT_TIME_SECTIONS)[number];

type TimeSlot = {
  key: string;
  t: Date;
  label: string;
  selectable: boolean;
  reason?: string;
};

function sectionToHour(section: EventTimeSection) {
  switch (section) {
    case 'Morning':
      return 9;
    case 'Afternoon':
      return 14;
    case 'Evening':
      return 19;
  }
}

function hourToSection(hour: number): EventTimeSection {
  if (hour >= 19) {
    return 'Evening';
  } else if (hour >= 14) {
    return 'Afternoon';
  } else {
    return 'Morning';
  }
}

function EventTimeSelect(props: {
  day: Date;
  selected: Date | null;
  checkTime?: (time: Date) => boolean | string;
  onChange: (v: Date) => void;
}) {
  const { day, selected, checkTime, onChange } = props;

  const [activeSection, setActiveSection] = useState<EventTimeSection>(() =>
    selected ? hourToSection(selected.getHours()) : 'Morning'
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const slots: TimeSlot[] = useMemo(() => {
    return eachMinuteOfInterval(
      {
        start: startOfDay(day),
        end: endOfDay(day),
      },
      { step: 15 }
    ).map((t) => {
      const checkResult = checkTime ? checkTime(t) : true;
      const selectable = checkResult === true;
      return {
        key: t.toISOString(),
        t,
        label: format(t, 'h:mm aa'),
        selectable,
        reason: isString(checkResult) ? checkResult : '',
      };
    });
  }, [checkTime, day]);

  const isSelectedTime = (time: Date) =>
    selected
      ? selected.getHours() === time.getHours() &&
        selected.getMinutes() === time.getMinutes()
      : false;

  const handleWaypointEnter = throttle((slot: TimeSlot) => {
    setActiveSection(hourToSection(slot.t.getHours()));
  }, 200);

  const handleClickSection = (section: EventTimeSection) => {
    const hour = sectionToHour(section);
    const index = slots.findIndex((slot) => slot.t.getHours() >= hour);
    if (index === -1) return;
    containerRef.current?.children[index]?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  useEffectOnce(() => {
    const selectedIndex = selected
      ? slots.findIndex((slot) => slot.t.getHours() >= selected.getHours())
      : slots.findIndex(
          (slot) =>
            slot.t.getHours() >= sectionToHour('Morning') && slot.selectable
        );
    if (selectedIndex === -1) return;

    containerRef.current?.children[selectedIndex]?.scrollIntoView({
      block: 'center',
    });
  });

  return (
    <div
      className='
        flex-1 w-84 max-h-108 bg-main-layer rounded-r-xl
        bg-opacity-80 pt-7.5
        flex flex-col gap-3
      '
    >
      <div className={`relative w-full px-4 flex items-center justify-center`}>
        <div
          className={`w-full h-10 rounded-xl
            flex justify-center items-center
            text-base font-bold
          text-white bg-lp-gray-009
          `}
        >
          {format(day, 'EE d MMM yyyy')}
        </div>
        <div className='absolute top-full -mt-2'>
          <div className='bg-modal border border-secondary rounded-xl p-0.5 flex items-center gap-0.5'>
            {EVENT_TIME_SECTIONS.map((section) => (
              <button
                type='button'
                key={section}
                className={`w-20 h-5 rounded-xl text-3xs font-light transition-all ${
                  activeSection === section ? 'bg-lp-gray-003' : ''
                }`}
                onClick={() => handleClickSection(section)}
              >
                {section}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div
        className='
          w-full flex-1 px-4 py-3
          overflow-auto scrollbar grid grid-cols-2 gap-2.5
        '
        style={{
          maskImage:
            'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #CBCBCB 8%, #7E7E7E 94.21%, rgba(115, 115, 115, 0.00) 100%)',
        }}
        ref={containerRef}
      >
        {slots.map((slot) => {
          return (
            <Waypoint
              key={slot.key}
              onEnter={() => handleWaypointEnter(slot)}
              topOffset='50%'
            >
              <div className='relative group flex justify-center'>
                <button
                  style={{ scrollMarginTop: 12 }}
                  type='button'
                  onClick={() => onChange(slot.t)}
                  disabled={!slot.selectable}
                  className={`
                  w-full h-10 rounded-xl
                  flex justify-center items-center text-sms
                  border ${
                    isSelectedTime(slot.t) ? 'border-white' : 'border-secondary'
                  }
                  text-white ${
                    slot.selectable
                      ? 'hover:bg-lp-gray-002'
                      : 'text-opacity-20 cursor-default'
                  }
                `}
                >
                  {slot.label}
                </button>
                {slot.reason && (
                  <div
                    className='
                    invisible group-hover:visible
                    absolute z-5 top-full
                    mt-0.5 max-w-full
                    bg-black rounded border border-secondary
                    p-2 text-3xs text-white text-center
                  '
                  >
                    {slot.reason}
                  </div>
                )}
              </div>
            </Waypoint>
          );
        })}
      </div>
    </div>
  );
}

function EventDetailNotesContainer(props: {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  purpose: 'info' | 'warning' | 'error' | 'success';
  onContinue?: () => void;
}) {
  const { title, subtitle, purpose, onContinue } = props;

  return (
    <div
      className={`
        bg-main-layer rounded-xl px-5 py-3 flex justify-center items-center gap-4
        ${match(purpose)
          .with('info', () => 'text-white')
          .with('warning', () => 'text-tertiary')
          .with('error', () => 'text-red-006')
          .with('success', () => 'text-green-001')
          .run()}
      `}
    >
      <CalendarIcon className='w-6 h-6 fill-current' />

      <div className='max-w-80 flex flex-col gap-1'>
        <div className='text-sms font-bold'>{title}</div>
        <div className='text-3xs font-medium text-icon-gray'>{subtitle}</div>
      </div>

      {onContinue && (
        <button
          type='button'
          onClick={onContinue}
          className='btn-primary w-33 h-10'
        >
          Continue
        </button>
      )}
    </div>
  );
}

function EventDetailNotesLive(props: {
  timezone: string | null;
  day: Date | null;
  time: Date | null;
  onContinue: () => void;
}) {
  const { timezone, day, time, onContinue } = props;

  if (!timezone) return null;

  if (!day || !time) {
    return (
      <EventDetailNotesContainer
        title={'All experiences require confirmation'}
        subtitle='Once you select a date and time, we need to confirm host availability'
        purpose='info'
      />
    );
  }

  return (
    <EventDetailNotesContainer
      title='Availability confirmation needed'
      subtitle={`We'll let you know if the host is available`}
      purpose='warning'
      onContinue={time ? onContinue : undefined}
    />
  );
}

function useTimezone(init?: string | null, key = 'timezone') {
  const [tz, setTz] = useState<string>(
    () =>
      init ||
      localStorage.getItem(key) ||
      Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const setTimezone = useCallback(
    (tz: string) => {
      setTz(tz);
      localStorage.setItem(key, tz);
    },
    [key]
  );

  return [tz, setTimezone] as const;
}

function EventTimePicker(props: {
  eventType: EventType;
  pack?: DtoGamePack | null;
  eventTime?: EventTime | null;
  onSelect: (eventTime: EventTime) => void;
}) {
  const { eventType, pack } = props;

  const [timezone, setTimezone] = useTimezone(props.eventTime?.timezone);
  const [day, setDay] = useState<Date | null>(() =>
    props.eventTime
      ? utcToZonedTime(props.eventTime.startDate, props.eventTime.timezone)
      : null
  );
  const [time, setTime] = useState<Date | null>(() =>
    props.eventTime
      ? utcToZonedTime(props.eventTime.startDate, props.eventTime.timezone)
      : null
  );

  const handleContinue = (t = time) => {
    if (!timezone || !day || !t) {
      return;
    }

    const startDate = zonedTimeToUtc(
      new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate(),
        t.getHours(),
        t.getMinutes(),
        0,
        0
      ),
      timezone
    );
    props.onSelect({
      timezone,
      startDate,
    });
  };

  const checkDay = useCallback(
    (day: Date) => {
      if (pack && !isGamePackLaunched(pack, endOfDay(day))) {
        return 'This experience is not launched at this time';
      }

      switch (eventType) {
        case EnumsEventType.EventTypeLive:
          if (EventScheduleUtils.IsDateTooSoon(day)) {
            return 'This date is too soon for us to organize';
          }
          if (EventScheduleUtils.IsAmericaHoliday(day)) {
            return 'This date falls on a US holiday';
          }
          return true;
        case EnumsEventType.EventTypeOnd:
        default:
          return true;
      }
    },
    [eventType, pack]
  );

  const checkTime = useCallback(
    (time: Date) => {
      if (pack && !isGamePackLaunched(pack, time)) {
        return 'This experience is not launched at this time';
      }

      return true;
    },
    [pack]
  );

  return (
    <div>
      <div className='text-3.5xl font-bold text-center'>Select Date & Time</div>
      <div className='mt-7.5 flex flex-col items-center gap-5'>
        <TimezoneSelect timezone={timezone} onSelect={setTimezone} />

        {!day && (
          <>
            <EventDatePicker
              monthsShown={2}
              className='rounded-xl border border-secondary'
              selected={day}
              onChange={setDay}
              checkDay={checkDay}
            />
          </>
        )}

        {!!day && (
          <div className='flex rounded-xl border border-secondary'>
            <EventDatePicker
              monthsShown={1}
              selected={day}
              className='rounded-l-xl'
              onChange={setDay}
              checkDay={checkDay}
            />

            <EventTimeSelect
              day={day}
              selected={time}
              onChange={(t) => {
                setTime(t);
                if (eventType === EnumsEventType.EventTypeOnd) {
                  handleContinue(t);
                }
              }}
              checkTime={checkTime}
            />
          </div>
        )}

        {props.eventType === 'live' && (
          <EventDetailNotesLive
            timezone={timezone}
            day={day}
            time={time}
            onContinue={() => handleContinue()}
          />
        )}

        {props.eventType === EnumsEventType.EventTypeOnd &&
          pack &&
          !isGamePackLaunched(pack) && (
            <EventDetailNotesContainer
              title={`This experience launches ${format(
                new Date(pack.detailSettings.availability?.launchDate ?? ''),
                'M/d/yy'
              )}`}
              subtitle='You must schedule your event for that day or later'
              purpose='warning'
            />
          )}
      </div>
    </div>
  );
}

export function useTriggerEventTimePicker() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback(
    async (props: {
      eventType: EventType;
      eventTime?: EventTime | null;
      pack: DtoGamePack | null;
      onSelect: (eventTime: EventTime) => void;
      onCancel?: () => void;
    }) => {
      await triggerModal({
        kind: 'custom',
        containerClassName: 'bg-none',
        element: (p) => (
          <div
            className='fixed inset-0 bg-lp-black-004 text-white'
            style={{
              backdropFilter: 'blur(2.5px)',
            }}
          >
            <button
              type='button'
              className='
                absolute top-0 right-0 z-50 
                icon-btn-no-shadow bg-transparent w-10 h-10 text-white rounded-none
              '
              onClick={() => {
                p.internalOnCancel();
                props.onCancel?.();
              }}
            >
              <XIcon />
            </button>

            <div className='w-full h-full overflow-auto scrollbar p-4 pt-15'>
              <EventTimePicker
                eventType={props.eventType}
                eventTime={props.eventTime}
                pack={props.pack}
                onSelect={(eventTime) => {
                  props.onSelect(eventTime);
                  p.internalOnConfirm();
                }}
              />
            </div>
          </div>
        ),
      });
    }
  );
}
