import { useMemo } from 'react';
import ReactSelect, { type GroupBase, type GroupProps } from 'react-select';
import { type ITimezoneOption, useTimezoneSelect } from 'react-timezone-select';

import { useInstance } from '../hooks/useInstance';
import { buildReactSelectStyles } from '../utils/react-select';

interface TimezoneGroupedOption {
  label: string;
  options: ITimezoneOption[];
}

function TimezoneSelectGroup(
  props: GroupProps<ITimezoneOption, false, GroupBase<ITimezoneOption>>
) {
  const { data, options, selectOption } = props;

  return (
    <div className='w-full py-2.5'>
      <div className='px-5 text-3xs font-medium text-icon-gray'>
        {data.label}
      </div>
      <div className='mt-2.5 flex flex-col'>
        {options.map((option) => {
          const index = option.label.indexOf(' ');
          const offset = option.label.slice(1, index - 1);
          const name = option.label.slice(index + 1);

          return (
            <div
              key={option.value}
              onClick={() => selectOption(option)}
              className='h-8 px-5 hover:bg-lp-gray-002 cursor-pointer flex justify-between items-center gap-1 text-sms'
            >
              <div className='truncate'>{name}</div>
              <div className='text-icon-gray'>{offset}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function TimezoneSelect(props: {
  timezone: string | null;
  onSelect: (timezone: string) => void;
  autoFocus?: boolean;
  menuIsOpen?: boolean;
}) {
  const { timezone, onSelect, autoFocus, menuIsOpen } = props;

  const { options, parseTimezone } = useTimezoneSelect({});
  const styles = useInstance(() =>
    buildReactSelectStyles<ITimezoneOption>({
      override: { control: { height: 60 } },
    })
  );

  const groupedOptions = useMemo(() => {
    const res: TimezoneGroupedOption[] = [];
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentOption = parseTimezone(currentTimezone);
    res.push({
      label: 'Your Current Timezone',
      options: [currentOption],
    });
    res.push({
      label: 'US Timezones',
      options: options.filter((option) => option.value.includes('America')),
    });
    res.push({
      label: 'All Timezones',
      options: options.filter(
        (option) =>
          !option.value.includes('America') &&
          option.value !== currentOption.value
      ),
    });
    return res;
  }, [options, parseTimezone]);

  return (
    <ReactSelect<ITimezoneOption>
      value={timezone ? parseTimezone(timezone) : null}
      options={groupedOptions}
      onChange={(option) => option && onSelect(option.value)}
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-100'
      menuIsOpen={menuIsOpen}
      autoFocus={autoFocus}
      placeholder='Search a Location or Timezone'
      noOptionsMessage={() => 'No matching locations or timezones'}
      components={{
        Group: TimezoneSelectGroup,
      }}
    />
  );
}
