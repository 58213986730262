import {
  addMonths,
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import isString from 'lodash/isString';
import { useState } from 'react';

import { ArrowLeftIcon, ArrowRightIcon } from '../icons/Arrows';

function ArrowLeft(props: { disabled?: boolean; onClick: () => void }) {
  return (
    <button
      type='button'
      {...props}
      className={`
        flex-none btn
        w-7.5 h-7.5 bg-lp-black-004 rounded-full 
        text-secondary hover:text-white
        border border-secondary
        flex justify-center items-center
      `}
    >
      <ArrowLeftIcon className='w-3.5 h-3.5 fill-current' />
    </button>
  );
}

function ArrowRight(props: { disabled?: boolean; onClick: () => void }) {
  return (
    <button
      type='button'
      {...props}
      className={`
        flex-none btn
        w-7.5 h-7.5 bg-lp-black-004 rounded-full 
        text-secondary hover:text-white
        border border-secondary
        flex justify-center items-center
      `}
    >
      <ArrowRightIcon className='w-3.5 h-3.5 fill-current' />
    </button>
  );
}

export interface EventCalendarMonthDayProps {
  day: Date;
  month: Date;
  selected?: Date | null;
  onClick: () => void;
  checkDay?: (day: Date) => boolean | string;
}

function EventCalendarMonthDay(props: EventCalendarMonthDayProps) {
  const { day, month, selected, onClick, checkDay } = props;

  const isCurrentMonth = isSameMonth(day, month);
  const isHistorical = isBefore(day, startOfDay(new Date()));
  const isToday = isSameDay(day, new Date());
  const checkResult = checkDay ? checkDay(day) : true;
  const passed = checkResult === true;
  const selectable = isCurrentMonth && !isHistorical && passed;
  const isSelected = selected ? isSameDay(day, selected) : false;

  return (
    <div className='relative group'>
      <button
        type='button'
        onClick={onClick}
        disabled={!selectable}
        className={`
        relative w-11.5 h-11.5 rounded-none
        flex justify-center items-center
        border ${isSelected ? 'border-white' : 'border-secondary'}
        text-white
        ${
          !isCurrentMonth || isHistorical
            ? 'text-opacity-20 cursor-default'
            : !passed
            ? 'text-opacity-20 bg-lp-gray-009 cursor-default'
            : 'hover:bg-lp-gray-002'
        }
      `}
      >
        <p>{format(day, 'd')}</p>

        {isToday && (
          <div className='absolute bottom-2 w-4 h-1 rounded bg-lp-gray-010' />
        )}
      </button>

      {isString(checkResult) && (
        <div
          className='
            invisible group-hover:visible
            absolute z-5 top-full left-1/2 mt-1
            bg-black rounded border border-secondary
            p-2 text-3xs text-white whitespace-nowrap
          '
        >
          {checkResult}
        </div>
      )}
    </div>
  );
}

export function EventCalendarMonth(props: {
  checkDay?: (day: Date) => boolean | string;
  monthDate: Date;
  selected?: Date | null;
  arrowLeft?: React.ReactNode;
  arrowRight?: React.ReactNode;
  onClick: (day: Date) => void;
}) {
  const { monthDate, arrowLeft, arrowRight, onClick, checkDay } = props;

  const start = startOfWeek(startOfMonth(monthDate));
  const end = endOfWeek(endOfMonth(monthDate));
  const days = eachDayOfInterval({ start, end });

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between items-center'>
        <div>{arrowLeft}</div>
        <div className='text-xl font-bold'>
          {monthDate.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric',
          })}
        </div>
        <div>{arrowRight}</div>
      </div>
      <div className='mt-5 grid grid-cols-7'>
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
          <div
            key={day}
            className='
              w-11.5 h-11.5
              flex justify-center items-center text-icon-gray
            '
          >
            {day}
          </div>
        ))}
      </div>
      <div className='grid grid-cols-7'>
        {days.map((day) => (
          <EventCalendarMonthDay
            key={day.toISOString()}
            checkDay={checkDay}
            day={day}
            month={monthDate}
            selected={props.selected}
            onClick={() => onClick(day)}
          />
        ))}
      </div>
    </div>
  );
}

export function EventDatePicker(props: {
  selected?: Date | null;
  onChange: (date: Date) => void;
  monthsShown?: number;
  className?: string;
  checkDay?: (day: Date) => boolean | string;
}) {
  const { selected, onChange, monthsShown = 1 } = props;

  const [currentDate, setCurrentDate] = useState(selected || new Date());

  const months = eachMonthOfInterval({
    start: currentDate,
    end: addMonths(currentDate, monthsShown - 1),
  });

  return (
    <div
      className={`
      bg-modal border border-[#232325]
        p-7.5 flex gap-5
        ${props.className}`}
    >
      {months.map((monthDate, index) => (
        <EventCalendarMonth
          key={monthDate.toISOString()}
          checkDay={props.checkDay}
          selected={props.selected}
          monthDate={monthDate}
          onClick={onChange}
          arrowLeft={
            index === 0 ? (
              <ArrowLeft
                disabled={isSameMonth(monthDate, new Date())}
                onClick={() => setCurrentDate(addMonths(currentDate, -1))}
              />
            ) : null
          }
          arrowRight={
            index === months.length - 1 ? (
              <ArrowRight
                onClick={() => setCurrentDate(addMonths(currentDate, 1))}
              />
            ) : null
          }
        />
      ))}
    </div>
  );
}
